<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col justify-center w-full relative">
    <nuxt-link to="/">
      <img src="public/layoutParts/header.png" alt="" />
    </nuxt-link>
    <div
      v-if="userWitch"
      class="flex justify-between lg:justify-end items-center w-full bg-moonlight-gray py-2 px-4"
    >
      <div
        class="lg:hidden aspect-square h-fit aspect-square p-2 border-moonlight-gold text-moonlight-gold border text-3xl rounded-xl flex items-center justify-center cursor-pointer"
        @click="isMobileSidebarOpen = true"
      >
        <Icon
          name="solar:hamburger-menu-outline"
          class="text-moonlight-gold"
          size="1.5rem"
        />
      </div>
      <Menu as="div" class="relative">
        <MenuButton
          class="md:hidden border-moonlight-gold border rounded-xl flex justify-center items-center p-2 aspect-square h-fit"
        >
          <Icon
            name="gravity-ui:magic-wand"
            class="text-moonlight-gold"
            size="1.5rem"
          />
        </MenuButton>
        <MenuItems
          as="div"
          class="absolute md:hidden right-0 h-fit z-10 bg-white rounded-xl shadow flex flex-col items-center w-fit p-2 mt-2 border-moonlight-gold gap-2"
        >
          <MenuItem>
            <StructureNavbarWitch />
          </MenuItem>
          <MenuItem>
            <StructureNavbarWitchDress />
          </MenuItem>
          <MenuItem>
            <StructureNavbarFairies />
          </MenuItem>
          <MenuItem>
            <StructureNavbarCloset />
          </MenuItem>
          <MenuItem>
            <StructureNavbarInventory />
          </MenuItem>
          <MenuItem>
            <login />
          </MenuItem>
        </MenuItems>
      </Menu>
      <div class="hidden md:flex justify-end items-center gap-3 w-full px-2">
        <StructureNavbarWitch />
        <StructureNavbarWitchDress />
        <StructureNavbarFairies />
        <StructureNavbarCloset />
        <StructureNavbarInventory />
        <login />
      </div>
    </div>
    <div
      v-if="!userWitch"
      class="flex justify-end w-full bg-moonlight-gray py-2 px-4"
    >
      <login />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import Login from "@/pages/user/Login.vue";
import useUserStore from "~/stores/useUserStore";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { userWitch } = storeToRefs(userStore);
const pocketBaseStore = usePocketBase();
const { logout } = pocketBaseStore;
const { isMobileSidebarOpen } = storeToRefs(useSidebarStore());
</script>

<style lang="css"></style>
