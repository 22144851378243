<template>
  <div class="bg-opacity-5 rounded-full h-16 overflow-clip aspect-square">
    <img
      v-if="currentDress?.image"
      class="object-cover h-52"
      style="object-position: 50% -15px"
      :src="currentDress.image"
      alt=""
    >
    <div
      v-else
      class="h-16 aspect-square rounded-full bg-white flex items-center justify-center text-xl font-star"
    >
      ?
    </div>
  </div>
</template>

<script setup lang="ts">
import { useWitchSchool } from "~/composables/useWitchSchool";

const props = defineProps<{
  witchId: string;
}>();

const { witchId } = toRefs(props);

const { currentDress } = useWitchSchool(witchId);
</script>
