<template>
  <div class="flex text-sm rounded-full bg-[#6b88bb] border-2 border-[#6b88bb]">
    <div class="bg-opacity-5 rounded-full">
      <NuxtLink :to="`/witch/${userWitch?.id}/school`">
        <CommonAvatarWitchSchool
          v-if="userWitch"
          :witch-id="userWitch.id"
          is-dress
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const { userWitch } = storeToRefs(useUserStore());
</script>
