<template>
  <div class="bg-[#24375f] text-gray-100 p-1 font-main">
    <div
      class="border border-[#405182] p-1 pt-5 relative flex flex-col items-center justify-center gap-4"
    >
      <div class="absolute w-full flex justify-center -top-10">
        <BaseButton class="gap-[60px] scale-75">
          <div class="text-3xl">Main</div></BaseButton
        >
      </div>
      <slot />
      <img src="public/layoutParts/navitabelle-dekounten.png" alt="" >
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="css" scoped></style>
