<template>
  <div
    class="flex text-sm rounded-full bg-[#6b88bb] border-2 border-[#6b88bb] aspect-square"
  >
    <div class="bg-opacity-5 rounded-full">
      <NuxtLink :to="`/witch/${userWitch?.id}/profile`">
        <CommonAvatarWitch v-if="userWitch" :witch-id="userWitch.id" />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const { userWitch } = storeToRefs(useUserStore());
</script>
