<template>
  <div
    class="bg-gradient-to-r from-[#33416a] via-[#465c83] to-[#33416a] flex flex-col bg-fixed min-h-dvh overflow-scroll"
  >
    <div id="main" class="grow flex flex-col min-w-full min-h-full relative">
      <div class="min-h-full grow">
        <TransitionRoot as="template" :show="isMobileSidebarOpen">
          <Dialog
            as="div"
            class="relative z-50 lg:hidden"
            @close="isMobileSidebarOpen = false"
          >
            <TransitionChild
              as="template"
              enter="transition-opacity ease-linear duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div class="fixed inset-0 flex">
              <TransitionChild
                as="template"
                enter="transition ease-in-out duration-300 transform"
                enter-from="-translate-x-full"
                enter-to="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leave-from="translate-x-0"
                leave-to="-translate-x-full"
              >
                <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                  <div class="flex h-full overflow-y-auto">
                    <Sidebar class="grow" />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </TransitionRoot>

        <div
          class="w-1/4 absolute top-[100px] left-0 lg:flex justify-start z-20 hidden"
        >
          <Sidebar />
        </div>
        <main class="relative grow flex flex-col min-h-full">
          <!-- <div -->
          <!--   class="hidden lg:block loader h-full w-full overflow-clip absolute pointer-events-none z-10" -->
          <!-- > -->
          <!--   <span v-for="n in 20" :key="n">★</span> -->
          <!-- </div> -->
          <!---->
          <navbar />

          <div
            class="grow relative bg-moonlight-gray min-h-dvh overflow-scroll mt-3 mb-3 mx-3 p-4 rounded-lg"
          >
            <div class="flex lg:justify-end grow">
              <div class="lg:w-3/4 w-full min-h-full">
                <slot />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Navbar from "~/components/structure/Navbar.vue";
import Sidebar from "~/components/structure/Sidebar.vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const router = useRouter();

const { isMobileSidebarOpen } = storeToRefs(useSidebarStore());

watch(
  () => router.currentRoute.value,
  () => {
    isMobileSidebarOpen.value = false;
  },
);
</script>

<style>
.body {
  height: 100%;
}

.loader {
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.loader span {
  width: 20px;
  height: 21px;
  /*   margin: -280px 60px 54px  -34px; */
  background: url("public/layout/rose.png");
  opacity: 0;
  -webkit-animation: loader 10s infinite linear;
  -moz-animation: loader 10s infinite linear;
  animation: loader 10s infinite linear;
}

.loader span:nth-child(5n + 5) {
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.loader span:nth-child(3n + 2) {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.loader span:nth-child(2n + 5) {
  -webkit-animation-delay: 1.7s;
  -moz-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.loader span:nth-child(3n + 10) {
  -webkit-animation-delay: 2.7s;
  -moz-animation-delay: 2.7s;
  animation-delay: 2.7s;
}

.loader span:nth-child(7n + 2) {
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

.loader span:nth-child(4n + 5) {
  -webkit-animation-delay: 5.5s;
  -moz-animation-delay: 5.5s;
  animation-delay: 5.5s;
}

.loader span:nth-child(3n + 7) {
  -webkit-animation-delay: 8s;
  -moz-animation-delay: 8s;
  animation-delay: 8s;
}

@keyframes loader {
  0% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(0, 0vh) rotateZ(0deg);
  }
  25% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(0, 25vh) rotateZ(90deg);
  }
  75% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(5vw, 75vh) rotateZ(270deg);
  }
  100% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
  }
}

@-webkit-keyframes loader {
  0% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(0, 0vh) rotateZ(0deg);
  }
  25% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(0, 25vh) rotateZ(90deg);
  }
  75% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(5vw, 75vh) rotateZ(270deg);
  }
  100% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
  }
}

@-moz-keyframes loader {
  0% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(0, 0vh) rotateZ(0deg);
  }
  25% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(0, 25vh) rotateZ(90deg);
  }
  75% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(5vw, 75vh) rotateZ(270deg);
  }
  100% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
  }
}
</style>
