<template>
  <div v-for="fairy in userFairies" :key="fairy.id">
    <NuxtLink :to="`/fairy/${fairy.id}`">
      <CommonAvatarFairy :fairy-id="fairy.id" />
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const { userFairies } = storeToRefs(useUserFairyStore());
</script>
